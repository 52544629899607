import styles from "./styles.module.scss";

export const FeaturesColumns = ({ features }: any) => {
  return (
    <div className={styles.featureColumns}>
      <div className={styles.content}>
        {features?.map((feature: any) => (
          <div className={styles.column} key={feature.id}>
            <img
              className={styles.columnIcon}
              src={feature?.icon?._url}
              alt="Icon"
            />
            <div className={styles.info}>
              <p className={styles.title}>{feature?.title}</p>
              <p className={styles.text}>{feature?.subtitle}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
