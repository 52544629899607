import styles from "./styles.module.scss";
import { Options } from '@splidejs/splide';
import { Splide } from '@splidejs/react-splide';
import { ImageSet } from "../../component-nextv3/ImageSet";

export const CategoryCarousel = ({ categories }: any) => 
{
	const mainOptions : Options = {
    perPage    : 4,
    perMove    : 1,
    rewind     : true,
    rewindByDrag: true,
    gap        : '2rem',
    pagination : false,
    slideFocus : true,
    arrows     : true,
    drag	   : true,
    start      : 1,
    breakpoints: {
      1024: {
          perPage: 2.2,
      },
    }
    };

  return (
    <>
      {categories && categories.length > 0 && <div className={styles.categorySlider}>
        <div className={styles.content}>
          <div className={styles.top}>
            <p className={styles.title}>Categorias</p>
          </div>
          <Splide
            options={mainOptions}
            aria-labelledby="Banners"
            className={styles.slideContent}
				  > 
            {categories.map((category:any, index:any) => (
              <div key={index} className={styles.slideItem + ' splide__slide'}>
                <a href={`/categoria/${category?.slug}`}>
                  <ImageSet image={category?.mobile}/>                  
                </a>
                <p className={styles.name}>{category.name}</p>
              </div>
            ))}
          </Splide>
        </div>
      </div>}
    </>
  );
};
