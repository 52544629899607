import styles from './styles.module.scss'
import { Matches } from '../Matches'
import { GetStaticProps } from 'next'
import withHeader from '../../utils/withHeader'
import { FeaturesColumns } from '../FeaturesColumns'
import { calls } from '../../core-nextv3/util/call.api'
import { BannerSlider } from '../../varejo-nextv3/BannerSlider'
import { getDocument } from '../../core-nextv3/document/document.api'
import { CategoryCarousel } from '../../varejo-nextv3/CategoryCarousel'
import { CollectionCarousel } from '../../varejo-nextv3/CollectionCarousel'
import { NewestProductsSlider } from '../../varejo-nextv3/NewestProductsSlider'
import {
  collectionProductVariant,
  getProduct,
} from '../../core-nextv3/product/product.api'
import {
  COLOR_TABLE_SETTING,
  HOME_PAGE_SETTING,
  PARTIAL_PRODUCT_VARIANT_SETTING,
  POPUP_PAGE_SETTING,
  THEME_SETTING,
} from '../../setting/setting'

const HomePage = ({
  colorTable,
  homePage,
  categoryProducts,
  saleProducts,
  newsProducts,
}: any) => {
  return (
    <div className={styles.homePage}>
      <BannerSlider images={homePage?.bannersHome} />
      {homePage?.bannersMatchhes && <Matches homePage={homePage} />}
      {!THEME_SETTING.disabledFeatures && (
        <FeaturesColumns features={homePage?.features} />
      )}
      {homePage?.collectionProducts &&
        homePage?.collectionProducts.map((item: any, index: number) => (
          <NewestProductsSlider
            allLink={`/colecao/${item.slug}`}
            key={index}
            title={item.name}
            products={item.products}
            colorTable={colorTable}
          />
        ))}
      {categoryProducts &&
        categoryProducts.map((item: any, index: number) => (
          <NewestProductsSlider
            allLink={`/categoria/${item.slug}`}
            key={index}
            title={item.name}
            products={item.products}
            colorTable={colorTable}
          />
        ))}
      {newsProducts && newsProducts?.length > 0 && (
        <NewestProductsSlider
          allLink={`/novidades/`}
          title='Novidades'
          products={newsProducts}
          colorTable={colorTable}
        />
      )}
      {saleProducts && saleProducts?.length > 0 && (
        <NewestProductsSlider
          allLink={`/sale/`}
          title='Sale'
          products={saleProducts}
          colorTable={colorTable}
        />
      )}
      {/* {homePage?.bestSellers?.products && homePage?.bestSellers?.products?.length > 0 ? (<BestsellerMasonry bestSellers={homePage?.bestSellers} />) : null} */}
      {homePage.collection && homePage.collection.length > 0 && (
        <CollectionCarousel collection={homePage.collection} />
      )}
      {homePage.categories && (
        <CategoryCarousel categories={homePage.categories} />
      )}
      {/* <SaleBanner /> */}
      {/* <Journal /> */}
      {/* <Newsletter />  */}
      {/* <ProductModalHome popupPageDiscount={popupPageDiscount} /> */}
    </div>
  )
}

const getStaticProps: GetStaticProps = () =>
  withHeader(async () => {
    // HOME
    const [homePage, colorTable, popupPageResult] = await calls(
      getProduct(HOME_PAGE_SETTING),
      getDocument(COLOR_TABLE_SETTING),
      getDocument(POPUP_PAGE_SETTING)
    )

    const promises = []
    let categoryProducts = []

    //Not revalidated
    if (!homePage.data) {
      return {
        notFound: true,
        revalidate: false,
      }
    }

    // PRODUTOS DA CATEGORIA
    if (homePage.data && homePage.data.categoryProducts) {
      for (const item of homePage.data.categoryProducts) {
        promises.push(
          collectionProductVariant(
            PARTIAL_PRODUCT_VARIANT_SETTING.merge({
              perPage: 12,
              orderBy: 'categoryOrder',
              asc: false,
              where: [
                {
                  field: 'published',
                  operator: '==',
                  value: true,
                },
                {
                  field: 'indexes.categoriesxcolorxsize',
                  operator: 'combine',
                  value: [
                    {
                      referencePath: item.referencePath,
                    },
                  ],
                },
              ],
            })
          )
        )
      }

      const values: any = await Promise.all(promises)

      for (const key in homePage.data.categoryProducts) {
        homePage.data.categoryProducts[key].products =
          values[key].collection || []
      }

      categoryProducts = homePage.data.categoryProducts
    }

    let saleProducts: any = {}

    // SALE
    if (homePage.data && homePage.data.sale) {
      saleProducts = await collectionProductVariant(
        PARTIAL_PRODUCT_VARIANT_SETTING.merge({
          perPage: 12,
          orderBy: 'saleOrder',
          asc: false,
          where: [
            {
              field: 'published',
              operator: '==',
              value: true,
            },
            {
              field: 'indexes.hasSale',
              operator: '==',
              value: true,
            },
          ],
        })
      )
    }

    let newsProducts: any = {}

    // NOVIDADES
    if (homePage.data && homePage.data.news) {
      newsProducts = await collectionProductVariant(
        PARTIAL_PRODUCT_VARIANT_SETTING.merge({
          perPage: 12,
          orderBy: 'order',
          where: [
            {
              field: 'published',
              operator: '==',
              value: true,
            },
          ],
        })
      )
    }

    return {
      revalidate: THEME_SETTING.revalidate,
      props: {
        homePage: homePage?.data || [],
        categoryProducts: categoryProducts || [],
        colorTable: colorTable?.data || {},
        saleProducts: saleProducts?.collection || [],
        newsProducts: newsProducts?.collection || [],
        popupPage: popupPageResult?.data,
      },
    }
  })

export { getStaticProps as GetStaticProps, HomePage }
