import dynamic from "next/dynamic";
import styles from "./styles.module.scss";
import { AspectRatio } from "react-aspect-ratio";
import { useRef } from "react";

const ReactPlayer = dynamic(
	() => import('react-player'),
	{ ssr: false },
);

export const VideosSet = ({ video, autoplay = true, controls=false, loop=true, field='_url', aspectRatio = 2/3 }: any) => 
{
	const playerRef : any = useRef();

    return (
        <div className={styles.videosSet}>
			<AspectRatio ratio={aspectRatio}>
				<div className={styles.video}>
					<div>
					{/* DIV EXTRAS POR CAUSA DO PLUGIN DO CHROME DE SPEED */ }
					<ReactPlayer
						playerRef={playerRef}
						width="100%"
						height="100%"
						className={styles.player}
						playing={autoplay}
    					controls={controls}
						muted={true}
						loop={loop}
						playsinline={true}
						url={video[field]}
					/>
					</div>
				</div>				
			</AspectRatio>            
        </div>
    );
};
