import styles from './styles.module.scss'
import { useRouter } from 'next/router'
import { toast } from 'react-hot-toast'
import { FiCheck } from 'react-icons/fi'
import { useEffect, useState } from 'react'
import { BsBag, BsCheck } from 'react-icons/bs'
import { useCore } from '../../core-nextv3/core/core'
import { ImageSet } from '../../component-nextv3/ImageSet'
import { setItemCart } from '../../core-nextv3/cart/cart.api'
import { tagManager4 } from '../../core-nextv3/util/TagManager4'
import { useResponsive } from '../../core-nextv3/util/useResponsive'
import { addItemCartAnalytics } from '../../core-nextv3/analytics/analytics.api'
import {
  CART_SETTING,
  THEME_SETTING,
  parseSizeVariant,
} from '../../setting/setting'
import {
  hasStockByListVariant,
  hasStockByVariant,
} from '../../core-nextv3/stock/stock.util'
import {
  firstProductImage,
  getVarianteByValue,
  imageColorTableByVariant,
  nameWithVariant,
  secondProductImage,
} from '../../core-nextv3/product/product.util'
import {
  percentageByPaymentMethod,
  productInstallments,
  productDiscount,
  productPrice,
  productPromotionalPercentage,
  productPromotionalPrice,
  productRealPrice,
} from '../../core-nextv3/price/price.util'

// firstLevel1Variant: Caso de produtos relacionados
export function ProductItem({
  product,
  disabledQuery,
  colorTable,
  labelImageType = true,
}: any) {
  const { isDesktop } = useResponsive()
  const { query, push } = useRouter()
  const [size, setSize] = useState<any>(null)
  const [color, setColor] = useState<any>(null)
  const [isHovering, setIsHovering] = useState(false)
  const [loadingAddToCart, setLoadingAddToCart] = useState(false)
  const { user, setCart, installmentRule, setIsOpenCart } = useCore()

  useEffect(() => {
    if (product.imagesListVariant) {
      setColor(product.imagesListVariant[0])
    }
  }, [product])

  useEffect(() => {
    if (
      !disabledQuery &&
      query.color &&
      product.variant &&
      product.variant.length > 0
    ) {
      setColor(getVarianteByValue(query.color, product.variant[0].items))
    }
  }, [query])

  const handleAddToCart = async () => {
    const newData = {
      data: {
        product: product._parent,
        variant: [color, size],
        quantity: 1,
      },
    }

    toast.success('Adicionado ao carrinho...', {
      duration: 2000,
    })

    //console.error("new data", newData)

    setLoadingAddToCart(true)

    const result = await setItemCart(CART_SETTING.merge(newData))

    setLoadingAddToCart(false)

    if (!result.status) {
      return toast.error(result.error, {
        duration: 2000,
      })
    }

    setCart(result.data)

    toast.success('Produto adicionado com sucesso!')

    //não pode ser no product pq precisa das outras variants pra renderizar
    let productCopy = Object.assign({}, product)
    productCopy.variant = [color, size]
    tagManager4.addToCart('path', productCopy, 1, user)

    // ADD ITEM CART ANALYTICS
    addItemCartAnalytics(product, [color, size], 1)

    setSize(null)
    setIsOpenCart(true)
  }

  return (
    <>
      {hasStockByListVariant(product, product.imagesListVariant) && firstProductImage(product, [color]) &&
        productRealPrice(product, null, false) > 0 && (
          <div className={styles.container}>
            <div className={styles.imageBox}>
              {isDesktop && (
                <div
                  className={styles.productImage}
                  onMouseEnter={() => setIsHovering(true)}
                  onMouseLeave={() => setIsHovering(false)}
                >
                  <a
                    href={`/produto/${product?.slug}/?color=${
                      color?.value
                    }&size=${size?.value || ''}&imageType=${
                      product?.imageType?.value || ''
                    }`}
                    aria-label={product?.name}
                  >
                    {
                      <ImageSet
                        aspectRatio={THEME_SETTING.aspectRatio}
                        domainFrom={THEME_SETTING.domainFrom}
                        domainTo={THEME_SETTING.domainTo}
                        image={
                          isHovering
                            ? secondProductImage(product, [color])
                            : firstProductImage(product, [color])
                        }
                        width={800}
                      />

                      // hasProductThumbVideo(product, [color]) ?
                      // 	<VideosSet field="thumb" video={getProductVideo(product, [color])} autoplay={true} loop={true} controls={false}/>
                      // :
                      // 	<ImageSet aspectRatio={THEME_SETTING.aspectRatio} image={isHovering ? secondProductImage(product, [color]) : firstProductImage(product, [color])} width={800} />
                    }
                  </a>
                  {/* {productHasPromotional(product) && <span className={styles.promocional}>Sale</span>} */}
                  {/* {hasStockByListVariant(product, product.imagesListVariant) ? ( */}
                    <div>
                      {!THEME_SETTING.disabledPromotionalPercentage &&
                        productPromotionalPercentage(
                          product,
                          [color, size],
                          false
                        ) > 0 && (
                          <span className={styles.promocional}>
                            {' '}
                            -{productPromotionalPercentage(product, [
                              color,
                              size,
                            ], false)}%
                          </span>
                        )}
                    </div>
                  {/* ) : (
                    <span className={styles.soldOff}>Esgotado</span>
                  )} */}
                  {labelImageType && product.imageType && (
                    <span className={styles.imageType}>
                      {product.imageType.label}
                    </span>
                  )}
                  {product?.tags &&
                    product?.tags?.map((item: any, index: number) => (
                      <span key={index} className={styles.tag}>
                        {item.label}
                      </span>
                    ))}
                </div>
              )}

              {!isDesktop && (
                <div className={styles.productImage}>
                  <a
                    href={`/produto/${product?.slug}?color=${
                      color?.value
                    }&size=${size?.value || ''}&imageType=${
                      product?.imageType?.value || ''
                    }`}
                    aria-label={product?.name}
                  >
                    {
                      <ImageSet
                        aspectRatio={THEME_SETTING.aspectRatio}
                        domainFrom={THEME_SETTING.domainFrom}
                        domainTo={THEME_SETTING.domainTo}
                        image={firstProductImage(product, [color])}
                        width={480}
                      />

                      // hasProductThumbVideo(product, [color]) ?
                      // 	<VideosSet field="thumb" video={getProductVideo(product, [color])} autoplay={true} loop={true} controls={false}/>
                      // :
                      // 	<ImageSet aspectRatio={THEME_SETTING.aspectRatio}  image={firstProductImage(product, [color])} width={480} />
                    }
                  </a>
                  {hasStockByListVariant(product, product.imagesListVariant) ? (
                    <div>
                      {!THEME_SETTING.disabledPromotionalPercentage &&
                        productPromotionalPercentage(
                          product,
                          [color, size],
                          false
                        ) > 0 && (
                          <span className={styles.promocional}>
                            {' '}
                            -{' '}
                            {productPromotionalPercentage(product, [
                              color,
                              size,
                            ])}{' '}
                            OFF
                          </span>
                        )}
                    </div>
                  ) : (
                    <span className={styles.soldOff}>Esgotado</span>
                  )}
                  {labelImageType && product.imageType && (
                    <span className={styles.imageType}>
                      {product.imageType.label}
                    </span>
                  )}
                  {product?.tags &&
                    product?.tags?.map((item: any, index: number) => (
                      <span key={index} className={styles.tag}>
                        {item.label}
                      </span>
                    ))}
                </div>
              )}

              {color && size && (
                <button onClick={() => handleAddToCart()}>
                  {loadingAddToCart ? (
                    <p>
                      <FiCheck /> <span>Adicionado</span>
                    </p>
                  ) : (
                    <p>
                      <BsBag /> Comprar
                    </p>
                  )}
                </button>
              )}
            </div>

            <p className={styles.productName}>
              {nameWithVariant(product?.name, [color])}
            </p>

            {productPromotionalPrice(product, [color, size], false) > 0 ? (
              <div className={styles.prices}>
                <p className={styles.productPriceWithPromocional}>
                  {productPrice(product, [color, size])}
                </p>
                <p className={styles.productPricePromocional}>
                  {productPromotionalPrice(product, [color, size])}
                </p>
              </div>
            ) : (
              <p className={styles.productPrice}>
                {productPrice(product, [color, size])}
              </p>
            )}
            {/* <p className={styles.productPrice}>
					{productPrice(product)}
				</p> */}

            <p className={styles.productInstallments}>
              {productInstallments(
                product,
                installmentRule?.max,
                installmentRule?.minValue,
                [color, size]
              )}
            </p>

            {percentageByPaymentMethod('pix', installmentRule) > 0 && (
              <p className={styles.productPIXDiscount}>
                ou{' '}
                {productDiscount(
                  product,
                  percentageByPaymentMethod('pix', installmentRule),
                  [color, size]
                )}{' '}
                no PIX
              </p>
            )}

            {!THEME_SETTING.disabledDisplayColorVatiant &&
              product?.variant &&
              hasStockByListVariant(product, product.imagesListVariant) && (
                <div className={styles.ballsColor}>
                  {product?.variant[0]?.items?.map((item: any, index: number) =>
                    // <p key={index} data-tip={item.label} onClick={() => setColor(item)}>
                    // 	<ImageSet width={480} image={item.image} aspectRatio={1 / 1} />
                    // 	{item.value == color && <BsCheck />}
                    // </p>
                    colorTable &&
                    colorTable?.status &&
                    colorTable?.table?.length > 0 ? (
                      <p
                        key={index}
                        className={styles.colorVariantImage}
                        data-tip={item.label}
                        onClick={(e) => setColor(item)}
                      >
                        <ImageSet
                          width={480}
                          image={imageColorTableByVariant(colorTable, item)}
                          aspectRatio={1 / 1}
                        />
                        {item.value == color?.value && <BsCheck />}
                      </p>
                    ) : (
                      hasStockByVariant(product, item) &&
                      firstProductImage(product, [item]) && (
                        <p
                          key={index}
                          className={
                            styles.colorVariantText +
                            ' ' +
                            (item.value == color?.value
                              ? styles['selected']
                              : '')
                          }
                          //data-tip={item.label}
                          onClick={(e) => setColor(item)}
                        >
                          {item && item.value && item?.label}
                        </p>
                      )
                    )
                  )}
                </div>
              )}

            {product?.variant && (
              <div className={styles.ballsColor}>
                {product?.variant[1]?.items?.map(
                  (item: any, index: number) =>
                    hasStockByListVariant(product, [color, item]) && (
                      <p
                        key={index}
                        className={
                          styles.colorVariantText +
                          ' ' +
                          (item.value == size?.value ? styles['selected'] : '')
                        }
                        onClick={(e) => setSize(item)}
                      >
                        {item &&
                          item.value !== undefined &&
                          parseSizeVariant(item?.label)}
                      </p>
                    )
                )}
              </div>
            )}
          </div>
        )}
    </>
  )
}
